<template>
  <div>
    <div class="horizFlex">
        <label class="infobig">{{contact.name}}</label>
        <button @click="toggle()" class="togglebutton" v-if="showToggleButton">{{toggleText}}</button>
        
    </div>
    <label class="infobig">{{contact.phoneNumber}}</label>
     <linkbutton @click="edit()"  v-if="contact.canBeEdited" toplabel="(edit)"/>    
    <div v-if="showDetail">
        <div class="vertFlex">
           
                <div v-html="contact.infoText" class="infosmall"></div>
               

            
            <div v-html="contact.emailAddress" class="infosmall"></div>
            <!-- <div v-html="contact.website" class="infolink"></div> -->
            <a class="infolink" :href="contact.website" target="_blank">{{contact.website}}</a>
             <div v-html="contact.htmlText" class="infosmall"></div>
            <!-- <label class="infosmall">{{contact.infoText}}</label>
            <label class="infosmall">{{contact.emailAddress}}</label>
            <label class="infolink">{{contact.website}}</label> -->
        </div>
        
    </div>
     <!-- edit -->
      <actionmodal ref="editmodal"> 
        <template v-slot:body>
            <div >
                <label class="studentname">Edit Badge Information</label>
                <div class="entry">    
         
                    <textarea class="entrymulti"   v-model="contact.infoText" rows="2" cols="20"/>
                </div>
                <!-- <formentry label="new Value" v-model="contact.infoText"/> -->
                <smallbutton @click="save()" toplabel='Save' buttonclass="button buttonprimary" />
                <smallbutton @click="$refs.editmodal.closeModal()" toplabel='Cancel' buttonclass="button buttonprimary" />
            </div>
        </template>
      </actionmodal>
  </div>
</template>

<script>
import actionmodal from './controls/actionmodal.vue'
import formentry from'./controls/entry.vue'
import smallbutton from './controls/button.vue'
import linkbutton from './controls/linkbutton.vue'
export default {
    components:{
        actionmodal,
        formentry,
        smallbutton,
        linkbutton
    },
    name:"badgecontact",
    props:{
        contact:{
            type:Object,
            
        },   
    },
    data(){
        return{
            showDetail:false,
        }
    },
    computed:{
        toggleText(){
            if(this.showDetail){return "-"}
            else{return "+"}
        },
        showToggleButton(){
            if(this.contact.emailAddress==null && this.contact.website==null && this.contact.infoText==null){
                return false
            }else{return true}
        }
    },
    methods:{
        toggle(){
            this.showDetail=!this.showDetail
        },
        edit(){
            this.$refs.editmodal.openModal()
        },
        save(){
            this.$emit("save",this.contact.id, this.contact.infoText)
            this.$refs.editmodal.closeModal()
        }
    }
}
</script>

<style>
.togglebutton{
    border:none;
    margin:2px;
}
.entrymulti{
    visibility: visible;
    font-size:inherit;
    margin:0px 5px 0px 5px;
    
    text-align:left;
    resize:none;
    min-height:50px;
    width:100%;
}
</style>