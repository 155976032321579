import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        isReady:false,
        
        currentNav:'front',
        username:'',
        oldpassword:'',
        loggedInUser:{
            id:0,
            name:'',
        },
        loggedIn:false,
        didFailAuth:false,

        badgeData:{},
        imageBasePath:'',
        BadgeDesign:null,
        showHeader:true,
        token:null
    },
    mutations:{
        updateField,
        failAuth(state){
            state.loggedIn=false;
            state.didFailAuth=true;
        },
    },
    getters:{
        getField,
    },
    actions:{
        failedAuthLogout(context){
            context.commit('failAuth'); 
        },
    }
})