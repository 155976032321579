<template>
  <div>
      <canvas id="canvas" class="sigcanvas"/>
      <smallbutton @click="done()" toplabel='Done' buttonclass="button buttonhighlight" />
      <smallbutton @click="clear()" toplabel='Clear'  />
      <smallbutton @click="cancel()" toplabel='Cancel'  />
  </div>
  

</template>

<script>
import SignaturePad from 'signature_pad'
import smallbutton from './button.vue'
import format from '../../format.js'
export default {
    name:"signaturepad",
    components:{
        smallbutton
    },
    mixins:[format],
    props: {
        
        enteredName:{
          type:String,
          default:''
        },
        docName:{
          type:String,
          default:''
        },

    },
    data() {
		return {
            outImgUrl:null,
            imageBlob:null,
            signaturePad:null
		};
	},
    mounted(){
        var canvas = document.querySelector("canvas")
      
        this.signaturePad=new SignaturePad(canvas)
        window.addEventListener("resize", this.resizeCanvas);
        this.resizeCanvas();
    },
    methods:{
        resizeCanvas(){
             var canvas = document.querySelector("canvas")
            var ratio =  Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            var ctx =canvas.getContext("2d")
            ctx.scale(ratio, ratio);
            
            
            this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
        },
        async done(){
            var canvas = document.querySelector("canvas")
            var ctx =canvas.getContext("2d")
            ctx.fillStyle = 'black';
            ctx.fillText(this.enteredName,10,10)
            ctx.fillText(this.docName,10,20)
            var dt = this.$options.filters.formatDate_Today();
            ctx.fillText(dt,10,30)
            this.outImgUrl=this.signaturePad.toDataURL(); 
            this.imageBlob = await fetch(this.outImgUrl).then(r=>r.blob())
            this.$emit('done',this.imageBlob, this.outImgUrl)
        },
        clear(){
            this.signaturePad.clear();
        },
        cancel(){
            this.$emit('cancel')
        }
    
    }
}
</script>

<style>
.sigcanvas{
   
    width:100%;
    border:1px solid black;
}
</style>