<template>
  <div class="splashimg">
      <label class="wait">Please Wait...</label>
  </div>
</template>

<script>
export default {
    name:'splash',
    computed:{
        backgroundimage(){
            return './../' + window.SPLASH_IMAGE
        }
        
    },
    mounted(){
        document.body.style.setProperty('--splashimage', 'url('+ './../' + window.SPLASH_IMAGE +')');
    }
}
</script>

<style>
    .splashimg{
        height:100vh;
        width:100vw;
        display:flex;
        flex-direction: column;
        justify-content: center;
        /* background-image:url('../assets/grad_gray.png'); */
        background-image:var(--splashimage);
        
    }
    .wait{
        font-size:large;
    }
</style>