export default{
    data(){
        return{
            //colors
            backgroundColor:'#',
            headerColor:'#FFFFFF',
            frameColor:'#FFFFFF',
            buttonColor:'#E32323',
            highlightButtonColor:'#F7C142',
            headerButtonColor:'#E32323',
            
            textColor:'#0E2049',
            lightTextColor:'#FFFFFF',
            buttonTextColor:'#0E2049',
            headerTextColor:'#FFFFFF',
            headerButtonTextColor:'#0E2049',
            alertColor:'#E32323',
            successColor:'#699864',
            inactiveColor:'#C3C3C3',

            menuTextColor:'#0E2049',
            backTextColor:'#0E2049',
            /* primaryColor:'#346278',
            highlightColor:'#7EB3D7',
            alertColor:'#E32323',
            textColor:'#346278',
            successColor:'#699864',
            inactiveColor:'#C3C3C3',
            lightTextColor:'#FFFFFF',

            titleTextColor:'#FFFFFF',
            mainTextColor:'#0E2049',
            menuTextColor:'#0E2049',
            backTextColor:'#0E2049',
            titleBackgroundColor:'#7EB3D7',
            mainBackgroundColor:'#E8E8E8', */

            mainBackgroundImage:''
        }
    },
    methods:{
        setColors(){
            this.backgroundColor=window.BACKGROUND_COLOR
            this.headerColor=window.HEADER_COLOR
            this.frameColor=window.FRAME_COLOR

            this.buttonColor = window.BUTTON_COLOR
            this.highlightButtonColor = window.HIGHLIGHT_BUTTON_COLOR
            this.headerButtonColor=window.HEADER_BUTTON_COLOR

            this.textColor=window.TEXT_COLOR
            this.lightTextColor=window.LIGHT_TEXT_COLOR
            this.buttonTextColor=window.BUTTON_TEXT_COLOR
            this.headerTextColor=window.HEADER_TEXT_COLOR
            this.headerButtonTextColor=window.HEADER_BUTTON_TEXT_COLOR

            this.alertColor=window.ALERT_COLOR
            this.successColor=window.SUCCESS_COLOR
            this.inactiveColor=window.INACTIVE_COLOR

            this.backTextColor=window.TEXT_COLOR 
            this.menuTextColor=window.MENU_TEXT_COLOR
            /* this.primaryColor = window.PRIMARY_COLOR
            this.highlightColor = window.HIGHLIGHT_COLOR
            this.alertColor=window.ALERT_COLOR
            this.successColor=window.SUCCESS_COLOR
            this.inactiveColor=window.INACTIVE_COLOR
            this.textColor=window.TEXT_COLOR
            this.lightTextColor=window.LIGHT_TEXT_COLOR
            this.titleBackgroundColor= window.HIGHLIGHT_COLOR
            this.mainBackgroundColor=window.BACKGROUND_COLOR
            this.titleTextColor=window.TITLE_TEXT_COLOR
            this.backTextColor=window.TEXT_COLOR */
        },
        overrideColors(titleText, mainText, menuText,titleBackground,mainBackground, backText){
            this.headerTextColor =titleText
            this.textColor=mainText
            this.headerColor=titleBackground   
            this.backgroundColor=mainBackground

            this.menuTextColor=menuText
            this.backTextColor=backText
           /*  this.titleTextColor =titleText
            this.mainTextColor=mainText
            this.menuTextColor=menuText
            this.titleBackgroundColor=titleBackground   
            this.mainBackgroundColor=mainBackground
            this.backTextColor=backText */
        },
        setBackgroundImage(img){
            this.mainBackgroundImage=img;
        }
        
    },
    computed:{
        configStyle(){
            return{
                '--background-color':this.backgroundColor,
                '--header-color':this.headerColor,
                '--frame-color':this.frameColor,
                '--button-color':this.buttonColor,
                '--highlight-button-color':this.highlightButtonColor,
                '--header-button-color':this.headerButtonColor,
                '--text-color':this.textColor,
                '--light-text-color':this.lightTextColor,
                '--button-text-color':this.buttonTextColor,
                '--header-text-color':this.headerTextColor,
                '--header-button-text-color':this.headerButtonTextColor,
                '--alert-color':this.alertColor,
                '--success-color':this.successColor,
                '--inactive-color':this.inactiveColor,

                '--menu-text-color':this.menuTextColor,
                '--back-text-color':this.backTextColor
            }
            /* return{
                '--primary-color':this.primaryColor,
                '--highlight-color':this.highlightColor,
                '--alert-color':this.alertColor,
                '--success-color':this.successColor,
                '--inactive-color':this.inactiveColor,
                '--text-color':this.textColor,
                '--light-text-color':this.lightTextColor,

                '--title-text-color':this.titleTextColor,
                '--main-text-color':this.mainTextColor,
                '--menu-text-color':this.menuTextColor,
                '--back-text-color':this.backTextColor,
                '--title-background-color':this.titleBackgroundColor,
                '--main-background-color':this.mainBackgroundColor,
            } */
        },
        backgroundurl(){
            console.log(this.mainBackgroundImage)
            if(this.mainBackgroundImage!=null){
                if(this.mainBackgroundImage!=''){
                    return new URL(this.mainBackgroundImage)
                }
            }
               
            
        }
    }
    
}