import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'
import './registerServiceWorker'
// import Vue2TouchEvents from 'vue2-touch-events'
import plugin from '@serializedowen/vue-img-watermark'
import WebCam from 'vue-web-cam'
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)
Vue.config.productionTip = false

import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

/* Vue.use(Vue2TouchEvents,{

}) */
Vue.use(plugin)
Vue.use(WebCam)

new Vue({
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
