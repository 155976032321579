<template>
  <div id="app">
    <add-to-home-screen/>
    <Start msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Start from './components/Start.vue'
import AddToHomeScreen from './components/AddToHome.vue'
export default {
  name: 'App',
  components: {
    Start,
    AddToHomeScreen
  },
  data(){
    return{
      deferredPrompt:null
    }
  },
  created(){

      // Initialize deferredPrompt for use later to show browser install prompt.
      //let deferredPrompt;
      /* console.log("installsetup")
      window.addEventListener('beforeinstallprompt', (e) => {
          
          // Prevent the mini-infobar from appearing on mobile
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
          // Update UI notify the user they can install the PWA
          this.showInstallPromotion=true;
          // Optionally, send analytics event that PWA install promo was shown.
          console.log(`'beforeinstallprompt' event was fired.`);
      }); 
      
      window.addEventListener("appinstalled", () => {
        console.log('appinstalled')
        this.deferredPrompt = null;
      }); */
      
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding:0px;
  
}
</style>
